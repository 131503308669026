import { useState } from "react";
import Aside from "../../components/Aside";
import NavHeader from "../../components/NavHeader";
import { Button, Label, TextInput, Select } from "flowbite-react";
import axiosInstance from '../../axiosInstance';
import { useNavigate } from "react-router-dom";

export default function AddClient() {
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        role: ""
    });

    const navigate = useNavigate();

    // Function to handle form input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Function to submit the form data using Axios
    const handleAddClient = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post("/client/add", formData).then(() => {
                navigate("/client/list");
            });
            console.log("Post created:", response.data);
        } catch (error) {
            console.error("Error creating post:", error);
        }
    };

    return (
        <>
            <Aside />
            <NavHeader />
            <div id="main-content" className="relative h-screen w-auto mt-[73px] p-4 overflow-auto bg-gray-100 lg:ml-64 dark:bg-gray-900">
                <h5 className="text-center mb-4 text-xl">Tambah Client</h5>
                <form className="flex w-auto flex-col gap-4" onSubmit={handleAddClient}>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="title" value="Username" />
                        </div>
                        <TextInput
                            value={formData.username}
                            name="username"
                            onChange={handleChange}
                            type="text"
                            placeholder="Leave a text for title"
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="text" value="E-mail" />
                        </div>
                        <TextInput
                            id="text"
                            value={formData.email}
                            name="email"
                            onChange={handleChange}
                            placeholder="Leave a text for content..."
                            type="text"
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="text" value="role" />
                        </div>
                        <Select id="role" name="role" value={formData.role} onChange={handleChange} required>
                            <option>Choose Role</option>
                            <option value={"Admin"}>Admin</option>
                            <option value={"Users"}>Users</option>
                            <option value={"Support"}>Support</option>
                        </Select>
                    </div>
                    <Button type="submit">Submit</Button>
                </form>
            </div>
        </>
    );
}