import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/AuthProvider';

function PrivateRoute() {
  const { cookies } = useAuth();

  if (!cookies) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;  // Render the child routes (protected pages)
}
export default PrivateRoute;