import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import Cookies from "js-cookie";
import React, { createContext, useContext } from 'react';

// Create a context for auth
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to manage the authentication state
const AuthProvider = ({ children }) => {
    const cookies = Cookies.get("token");// Assuming `user` is null when not logged in
    const navigate = useNavigate();
    const loginAction = async (email, password) => {
        try {
            const user = await axiosInstance.post('/login', {
                email: email,
                password: password
            }).then(res => Cookies.set("token", res.data.token));
            if (user) return navigate("/")

        } catch (err) {
            console.error(err);
        }
    };

    const logOut = () => {
        axiosInstance.post("/logout")
        Cookies.remove("token");
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{ cookies, loginAction, logOut }}>
            {children}
        </AuthContext.Provider>
    );
};
export default AuthProvider;