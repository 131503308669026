import { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import NavHeader from "../../components/NavHeader";
import { Button, Label, TextInput } from "flowbite-react";
import axiosInstance from '../../axiosInstance';
import { useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';

export default function EditHost() {
    const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const [id, setID] = useState("");
    const [host_name, setName] = useState("");
    const [host_ip, setIp] = useState("");
    const [host_username, setUsername] = useState("");
    const [host_password, setPassword] = useState("");
    const [updated_at, setUpdatedAt] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    const navigate = useNavigate();
    const { id2 } = useParams();
    const CryptoJS = require("crypto-js");

    useEffect(() => {
        getHostById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getHostById = async () => {
        const response = await axiosInstance.get(`/host/${id2}`);
        const bytes = CryptoJS.AES.decrypt(response.data.host_password, ';UnBptH%$ff7:=s').toString(CryptoJS.enc.Utf8);
        const originalText = JSON.parse(bytes);
        setName(response.data.host_name);
        setIp(response.data.host_ip);
        setUsername(response.data.host_username);
        setPassword(originalText);
    };

    const newData = {
        id: id2,
        host_name: host_name,
        host_ip: host_ip,
        host_username: host_username,
        host_password: host_password,
        updated_at: updated_at
    }


    const handleUpdateEvent = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`/host/update/${id2}`, newData).then(() => {
                navigate("/host");
            });
            console.log("Post created:", response.data);
        } catch (error) {
            console.error("Error creating post:", error);
        }
    };

    return (
        <>
            <Aside />
            <NavHeader />
            <div id="main-content" className="relative h-screen w-auto mt-[73px] p-4 overflow-auto bg-gray-100 lg:ml-64 dark:bg-gray-900">
                <h5 className="text-center mb-4 text-xl">Edit Host</h5>
                <form className="flex w-auto flex-col gap-4" onSubmit={handleUpdateEvent}>
                    <input type="hidden" name="id" value={id2} onChange={() => setID(id2)} />
                    <input type="hidden" name="updated_at" value={updated_at} onChange={() => setUpdatedAt(now)} />
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="title" value="Host Name" />
                        </div>
                        <TextInput
                            value={host_name}
                            name="host_name"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Leave a text for title"
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="text" value="Host IP" />
                        </div>
                        <TextInput
                            id="text"
                            value={host_ip}
                            name="host_ip"
                            onChange={(e) => setIp(e.target.value)}
                            placeholder="Leave a text for content..."
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="title" value="Host Username" />
                        </div>
                        <TextInput
                            value={host_username}
                            name="host_username"
                            onChange={(e) => setUsername(e.target.value)}
                            type="text"
                            placeholder="Leave a text for title"
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="text" value="Host Password" />
                        </div>
                        <TextInput
                            id="text"
                            value={host_password}
                            name="host_password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Leave a text for content..."
                            required shadow />
                    </div>
                    <Button color="warning" type="submit">Update</Button>
                </form>
            </div>
        </>
    );
}