import React, { useState, useEffect } from "react";
import Aside from "../components/Aside";
import NavHeader from "../components/NavHeader";
import { useNavigate } from "react-router-dom";
import { Button } from 'flowbite-react';
import { FaPlus, FaPencil, FaTrash } from "react-icons/fa6";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import axiosInstance from '../axiosInstance';
import DataTable from 'react-data-table-component';

export default function Host() {
    const [openModalHost, setOpenModalHost] = useState(false);
    const navigate = useNavigate();

    const handleDeleteHost = async (id) => {
        try {
            await axiosInstance.delete(`host/delete`, { data: { id: id } })
            .then(
                getHost(),
                navigate("/host"),
                setOpenModalHost(true)
            ).finally(
                window.location.reload()
            )
            
        } catch (error) {
            console.error("Error deleting post:", error);
        }
    }

    const [host, setHost] = useState([]);

    const getHost = async () => {
        const response = await axiosInstance.get("/host");
        setHost(response.data);
    };

    const columns = [
        {
            name: 'No',
            cell: (row, index) => ++index,
        },
        {
            name: 'Host Name',
            selector: row => row.host_name,
            sortable: true,
        },
        {
            name: 'Host IP',
            selector: row => row.host_ip,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="flex space-x-2">
                    <Button
                        color="failure"
                        onClick={() => handleDeleteHost(row.id)}
                    >
                        <FaTrash className="h-5 w-5" />
                    </Button>
                    <Button color="warning"
                        as="a" href={`/host/edit/`+row.id}
                    >
                        <FaPencil className="h-5 w-5" />
                    </Button>
                </div>
            )
        }
    ];

    useEffect(() => {
        getHost();
    }, []);

    return (
        <>
            <Aside />
            <NavHeader />
            <div id="main-content" className="relative h-screen w-auto mt-[73px] p-4 overflow-auto bg-gray-100 lg:ml-64 dark:bg-gray-900">
                <div className="flex float-right">
                    <Button as="a" href="/host/add" className="h-9 w-44">
                        <FaPlus className="mr-2 mt-[-3px] h-5 w-5" />
                        <span className="mt-[-2.5px]">Tambah Host</span>
                    </Button>
                </div>
                <div className="mt-14 overflow-x-auto">
                    <DataTable
                        columns={columns}
                        data={host}
                        fixedHeader
                        title="List Host"
                        pagination
                    />
                </div>
            </div>
            <Dialog open={openModalHost} onClose={setOpenModalHost} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Host has been deleted.
                                        </DialogTitle>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setOpenModalHost(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Close
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}