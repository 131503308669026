// import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./pages/Login"
import Dashboard from './pages/Dashboard';
import Host from './pages/Host';
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from './router/PrivateRoute';
import AddHost from './pages/Host/AddHost';
import EditHost from './pages/Host/EditHost';
import Client from './pages/Client';
import AddClient from './pages/Client/AddClient';
import EditClient from './pages/Client/EditClient';
import ClientPrivilage from './pages/ClientPrivilage'
import AddClientPrivilage from './pages/ClientPrivilage/AddClientPrivilage';
import EditClientPrivilage from './pages/ClientPrivilage/EditClientPrivilage';

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/host" element={<Host />} />
            <Route path="/host/add" element={<AddHost />} />
            <Route path="/host/edit/:hostID" element={<EditHost />} />
            <Route path="/client/list" element={<Client />} />
            <Route path="/client/list/add" element={<AddClient />} />
            <Route path="/client/list/edit/:clientID" element={<EditClient />} />
            <Route path="/client/privilage" element={<ClientPrivilage />} />
            <Route path="/client/privilage/add" element={<AddClientPrivilage />} />
            <Route path="/client/privilage/edit/:clientPrivID" element={<EditClientPrivilage />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}