import { useState } from "react";
import Aside from "../../components/Aside";
import NavHeader from "../../components/NavHeader";
import { Button, Label, TextInput } from "flowbite-react";
import axiosInstance from '../../axiosInstance';
import { useNavigate } from "react-router-dom";

export default function AddHost() {
    const [formData, setFormData] = useState({
        host_name: "",
        host_ip: "",
        host_username: "",
        host_password: ""
    });

    const navigate = useNavigate();

    // Function to handle form input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Function to submit the form data using Axios
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.post("/host/add", formData).then(
                navigate("/host")
            ).finally(
                window.location.reload()
            );
        } catch (error) {
            console.error("Error creating post:", error);
        }
    };

    return (
        <>
            <Aside />
            <NavHeader />
            <div id="main-content" className="relative h-screen w-auto mt-[73px] p-4 overflow-auto bg-gray-100 lg:ml-64 dark:bg-gray-900">
                <h5 className="text-center mb-4 text-xl">Tambah Host</h5>
                <form className="flex w-auto flex-col gap-4" onSubmit={handleSubmit}>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="title" value="Nama Host" />
                        </div>
                        <TextInput
                            value={formData.host_name}
                            name="host_name"
                            onChange={handleChange}
                            type="text"
                            placeholder="Leave a text for title"
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="text" value="Host IP" />
                        </div>
                        <TextInput
                            id="text"
                            value={formData.host_ip}
                            name="host_ip"
                            onChange={handleChange}
                            placeholder="Leave a text for content..."
                            type="text"
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="title" value="Username Host" />
                        </div>
                        <TextInput
                            value={formData.host_username}
                            name="host_username"
                            onChange={handleChange}
                            type="text"
                            placeholder="Leave a text for title"
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="text" value="Password Host" />
                        </div>
                        <TextInput
                            id="text"
                            value={formData.host_password}
                            onChange={handleChange}
                            name="host_password"
                            placeholder="Leave a text for content..."
                            type="text"
                            required shadow />
                    </div>
                    <Button type="submit">Submit</Button>
                </form>
            </div>
        </>
    );
}