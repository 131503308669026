import { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import NavHeader from "../../components/NavHeader";
import { Button, Label } from "flowbite-react";
import axiosInstance from '../../axiosInstance';
import { useNavigate } from "react-router-dom";
import Select from "react-select";

export default function AddClientPrivilage() {
    const [host, setHost] = useState([])
    const [client, setClient] = useState([])
    const [host_id, setHostID] = useState([])
    const [client_id, setClientID] = useState([])

    const navigate = useNavigate();

    const addClientPrivilage = {
        host_id: host_id.value,
        client_id: client_id.value
    }

    // Function to submit the form data using Axios
    const handleAddClientPrivilage = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post("/client_privilage/add", addClientPrivilage).then(() => {
                navigate("/client/privilage");
            });
            console.log("Post created:", response.data);
        } catch (error) {
            console.error("Error creating post:", error);
        }
    };

    useEffect(() => {
        const fetchHost = async () => {
            try {
              const response = await axiosInstance.get(
                "/host",
              );
              const userOptions = response.data.map((host) => ({
                value: host.id, // using host's ID as the value
                label: host.host_name, // using host's name as the label
              }));
              setHost(userOptions);
            } catch (error) {
              console.error("Error fetching data: ", error);
            }
          };
          const fetchClient = async () => {
            try {
              const response = await axiosInstance.get(
                "/client",
              );
              const userOptions = response.data.map((client) => ({
                value: client.id, // using client's ID as the value
                label: client.username, // using client's name as the label
              }));
              setClient(userOptions);
            } catch (error) {
              console.error("Error fetching data: ", error);
            }
          };
          fetchClient();
          fetchHost();
    }, []);

    return (
        <>
            <Aside />
            <NavHeader />
            <div id="main-content" className="relative h-screen w-auto mt-[73px] p-4 overflow-auto bg-gray-100 lg:ml-64 dark:bg-gray-900">
                <h5 className="text-center mb-4 text-xl">Tambah Client Privilage</h5>
                <form className="flex w-auto flex-col gap-4" onSubmit={handleAddClientPrivilage}>

                    <div className="mb-2 block">
                        <Label htmlFor="host_id" value="Host" />
                    </div>
                    <Select
                        value={host.id}
                        name="host_id"
                        onChange={setHostID}
                        options={host}
                        defaultValue={host}
                    />
                    <div className="mb-2 block">
                        <Label htmlFor="client_id" value="Client" />
                    </div>
                    <Select
                        value={client.id}
                        name="client_id"
                        onChange={setClientID}
                        options={client}
                        defaultValue={client}
                    />
                    <Button type="submit">Submit</Button>
                </form>
            </div>
        </>
    );
}