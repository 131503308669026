import { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import NavHeader from "../../components/NavHeader";
import { Button, Label, TextInput } from "flowbite-react";
import axiosInstance from '../../axiosInstance';
import { useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';

export default function EditClient() {
    const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const [id, setID] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [updated_at, setUpdatedAt] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    const navigate = useNavigate();
    const {clientID} = useParams();

    useEffect(() => {
        getClientById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getClientById = async () => {
        const response = await axiosInstance.get(`/client/${clientID}`);
        setUsername(response.data.username);
        setEmail(response.data.email);
    };

    const updateClient = {
        id: clientID,
        username: username,
        email: email,
        updated_at: updated_at
    }


    const handleUpdateClient = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`/client/update/${clientID}`, updateClient).then(() => {
                navigate("/client/list");
            });
            console.log("Post created:", response.data);
        } catch (error) {
            console.error("Error creating post:", error);
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        navigate("/client/list");
        window.location.reload();
    }

    return (
        <>
            <Aside />
            <NavHeader />
            <div id="main-content" className="relative h-screen w-auto mt-[73px] p-4 overflow-auto bg-gray-100 lg:ml-64 dark:bg-gray-900">
                <h5 className="text-center mb-4 text-xl">Edit Client</h5>
                <form className="flex w-auto flex-col gap-4" onSubmit={handleUpdateClient}>
                    <input type="hidden" name="id" value={clientID} onChange={() => setID(clientID)} />
                    <input type="hidden" name="updated_at" value={updated_at} onChange={() => setUpdatedAt(now)} />
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="title" value="Username" />
                        </div>
                        <TextInput
                            value={username}
                            name="username"
                            onChange={(e) => setUsername(e.target.value)}
                            type="text"
                            placeholder="Leave a text for title"
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="text" value="E-mail" />
                        </div>
                        <TextInput
                            id="text"
                            value={email}
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Leave a text for content..."
                            required shadow />
                    </div>
                    <div className="flex flex-col sm:flex-row">
                        <div className="p-4">
                            <Button color="warning" type="submit">Update</Button>
                        </div>
                        <div className="p-4">
                            <Button color="failure" type="button" onClick={(e) => handleCancel(e)}>Cancel</Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}