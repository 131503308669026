import React, { useState, useEffect } from "react";
import Aside from '../components/Aside'
import NavHeader from '../components/NavHeader';
import axiosInstance from '../axiosInstance';

export default function Dashboard() {
    const [host, setHost] = useState([]);
    const [client, setClient] = useState([]);

    const getHost = async () => {
        const response = await axiosInstance.post("/host/count");
        setHost(response.data);
    };

    const getClient = async () => {
        const response = await axiosInstance.post("/client/count");
        setClient(response.data);
    };

    useEffect(() => {
        getClient();
        getHost();
    }, []);

    return (
        <div>
            <NavHeader />
            <Aside />
            <div id="main-content" className="relative h-screen w-auto mt-[73px] overflow-auto bg-gray-100 lg:ml-64 dark:bg-gray-900">
                <div className="max-w-full mx-4 py-6 sm:mx-auto sm:px-6 lg:px-8">
                    <div className="sm:flex sm:space-x-4">
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div className="bg-white p-5">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 className="text-sm leading-6 font-medium text-red-600">Client</h3>
                                        {client ? (
                                            <p className="text-3xl font-bold text-black">{client}</p>
                                        ) : (
                                            <p className="text-3xl font-bold text-black">0</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div className="bg-white p-5">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 className="text-sm leading-6 font-medium text-red-600">Host</h3>
                                        {host ? (
                                            <p className="text-3xl font-bold text-black">{host}</p>
                                        ) : (
                                            <p className="text-3xl font-bold text-black">0</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Chart /> */}
            </div >
        </div>
    );
}