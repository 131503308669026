import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from '../hooks/AuthProvider';

const PrivateRoute = () => {
    const { cookies } = useAuth();
    return cookies ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
