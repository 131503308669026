import { Avatar, Dropdown, Button } from 'flowbite-react';
import { useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, DialogPanel } from '@headlessui/react'
import logo from '../logo.png'
import { FaUserGroup, FaGauge, FaFilePen } from "react-icons/fa6";
import { useAuth } from "../hooks/AuthProvider";

function NavHeader() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const auth = useAuth();
    return (
        <div>
            <nav className="fixed top-0 z-50 w-full bg-white dark:bg-gray-800 dark:border-gray-700">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-start rtl:justify-end">
                            <div className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                                <Button color="light" onClick={() => setMobileMenuOpen(true)}>
                                    <div>
                                        <span className="sr-only">Open sidebar</span>
                                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                                        </svg>
                                    </div>
                                </Button>
                            </div>

                            <div className="flex ms-2 md:me-24">
                                <img src={logo} className="h-12 me-3" alt="FlowBite Logo" />
                            </div>
                        </div>
                        <div className="flex md:order-2">
                            <Dropdown
                                arrowIcon={false}
                                inline
                                label={
                                    <Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" rounded />
                                }
                            >
                                <Dropdown.Header>
                                    <span className="block text-sm">Test</span>
                                    <span className="block truncate text-sm font-medium">test@gmail.com</span>
                                </Dropdown.Header>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={() => auth.logOut()}>Sign out</Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-10" />
                    <DialogPanel className="fixed inset-y-0 left-0 z-10 w-full mt-14 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-end">
                            <button className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="flex-1 px-3 space-y-1 bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                            <ul className="pb-2 space-y-2">
                                <li>
                                    <a href="/" className="flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
                                        <FaGauge className="w-6 h-6" />
                                        <span className="ml-3">Dashboard</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/host" className="flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 hover:text-red-600 group dark:text-gray-200 dark:hover:bg-gray-700">
                                        <FaFilePen className="w-6 h-6" />
                                        <span className="ml-3">Host</span>
                                    </a>
                                </li>
                                <li>
                                    <Dropdown label="user" dismissOnClick={false} renderTrigger={() =>
                                        <span className="flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 hover:text-red-600 group dark:text-gray-200 dark:hover:bg-gray-700">
                                            <FaUserGroup className="w-6 h-6" />
                                            <span className="ml-3">User</span>
                                            <svg className="relative w-3 h-3 m-auto right-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                            </svg>
                                        </span>}>
                                        <Dropdown.Item as="a" href='/client/list'>List</Dropdown.Item>
                                        <Dropdown.Item as="a" href='/client/privilage'>Privilage</Dropdown.Item>
                                    </Dropdown>
                                </li>
                            </ul>
                        </div>
                    </DialogPanel>
                </Dialog>
            </nav>
        </div>
    );
}
export default NavHeader;