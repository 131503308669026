import { FaUserGroup, FaGauge, FaFilePen } from "react-icons/fa6";
import { Dropdown } from "flowbite-react";

function Aside() {
    return (
        <>
            <aside id="sidebar" className="fixed top-0 left-0 z-20 flex flex-col flex-shrink-0 hidden w-64 h-full pt-16 font-normal duration-75 lg:flex transition-width" aria-label="Sidebar">
                <div className="relative flex flex-col flex-1 min-h-0 pt-0 bg-white dark:bg-gray-800 dark:border-gray-700">
                    <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
                        <div className="flex-1 px-3 space-y-1 bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                            <ul className="pb-2 space-y-2">
                                <li>
                                    <a href="/" className="flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 hover:text-red-600 group dark:text-gray-200 dark:hover:bg-gray-700">
                                        <FaGauge className="w-6 h-6" />
                                        <span className="ml-3">Dashboard</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/host" className="flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 hover:text-red-600 group dark:text-gray-200 dark:hover:bg-gray-700">
                                        <FaFilePen className="w-6 h-6" />
                                        <span className="ml-3">Host</span>
                                    </a>
                                </li>
                                <li>
                                    <Dropdown label="user" dismissOnClick={false} renderTrigger={() =>
                                        <span className="flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 hover:text-red-600 group dark:text-gray-200 dark:hover:bg-gray-700">
                                            <FaUserGroup className="w-6 h-6" />
                                            <span className="ml-3">Client</span>
                                            <svg className="relative w-3 h-3 ml-32 right-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                            </svg>
                                        </span>}>
                                        <Dropdown.Item as="a" href='/client/list'>List</Dropdown.Item>
                                        <Dropdown.Item as="a" href='/client/privilage'>Privilage</Dropdown.Item>
                                    </Dropdown>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </aside>
            <div className="fixed inset-0 z-10 hidden bg-gray-900/50 dark:bg-gray-900/90" id="sidebarBackdrop"></div>
        </>
    );
}
export default Aside;