import { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import NavHeader from "../../components/NavHeader";
import { Button, Label } from "flowbite-react";
import axiosInstance from '../../axiosInstance';
import { useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import Select from "react-select";

export default function EditClientPrivilage() {
    const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const [id, setID] = useState("");
    const [client_id, setClient] = useState("");
    const [host_id, setHostID] = useState([]);
    const [host, setHost] = useState([])
    const [updated_at, setUpdatedAt] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    const navigate = useNavigate();
    const { id2 } = useParams();

    useEffect(() => {
        const fetchHost = async () => {
            try {
                const response = await axiosInstance.get(
                    "/host",
                );
                const userOptions = response.data.map((host) => ({
                    value: host.id, // using host's ID as the value
                    label: host.host_name, // using host's name as the label
                }));
                setHost(userOptions);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchHost();
        getClientById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getClientById = async () => {
        const response = await axiosInstance.get(`/client_privilage/${id2}`);
        setClient(response.data.client_id);
    };

    // console.log(host)

    const updateClient = {
        id: id2,
        host_id: host_id.value,
        client_id: client_id,
        updated_at: updated_at
    }


    const handleUpdateClient = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`/client_privilage/update/${id2}`, updateClient).then(() => {
                navigate("/client/privilage");
            });
            console.log("Post created:", response.data);
        } catch (error) {
            console.error("Error creating post:", error);
        }
    };

    return (
        <>
            <Aside />
            <NavHeader />
            <div id="main-content" className="relative h-screen w-auto mt-[73px] p-4 overflow-auto bg-gray-100 lg:ml-64 dark:bg-gray-900">
                <h5 className="text-center mb-4 text-xl">Edit Client</h5>
                <form className="flex w-auto flex-col gap-4" onSubmit={handleUpdateClient}>
                    <input type="hidden" name="id" value={id2} onChange={() => setID(id2)} />
                    <input type="hidden" name="client_id" value={client_id} />
                    <input type="hidden" name="updated_at" value={updated_at} onChange={() => setUpdatedAt(now)} />
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="text" value="Host" />
                        </div>
                        <Select
                            value={host.id}
                            name="host_id"
                            onChange={setHostID}
                            options={host}
                            defaultValue={host}
                        />
                    </div>
                    <Button color="warning" type="submit">Update</Button>
                </form>
            </div>
        </>
    );
}