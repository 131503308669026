import React, { createContext, useContext } from 'react';
import axiosInstance from "../axiosInstance";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const cookies = Cookies.get("token")
    const navigate = useNavigate();
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 1); // Expire in 1 hour
    const loginAction = async (email, password) => {
        try {
            const user = await axiosInstance.post('/login', {
                email: email,
                password: password
            }).then((res) =>
                Cookies.set("token", res.data.token, { expires: expirationDate }),
            )
            if (user) return navigate("/");

        } catch (err) {
            console.error(err);
        }
    };

    const logOut = () => {
        Cookies.remove("token");
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{ cookies, loginAction, logOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};