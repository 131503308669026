import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useState, useRef } from "react";
import logo from "../logo.png";
import { useAuth } from "../hooks/AuthProvider";
import axiosInstance from "../axiosInstance";
import { Button } from "flowbite-react";

const Login = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const recaptchaRef = useRef()
    const onChangeRecapthca = (recaptchaToken) => setToken(recaptchaToken);
    const [email, setName] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [captchatoken, setToken] = useState("");

    const onChangeUsername = (e) => {
        const email = e.target.value;
        setName(email);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        if (email && password && captchatoken) {
            await axiosInstance.post("/verify-captcha", {token: captchatoken})
            .then(auth.loginAction(email, password));
            setToken("");
        } else {
            alert("Please! provide a valid input");
            navigate("/login");
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-10 w-auto"
                    src={logo}
                    alt="IndoBSD"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleLogin}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Username
                        </label>
                        <div className="mt-2">
                            <input
                                id="username"
                                name="username"
                                type="text"
                                value={email}
                                onChange={onChangeUsername}
                                autoComplete="username"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                            <div className="text-sm">
                                <Link to="" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                    Forgot password?
                                </Link>
                            </div>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                value={password}
                                onChange={onChangePassword}
                                autoComplete="current-password"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <ReCAPTCHA
                        sitekey="6LdA0ZkqAAAAANuDvwKYbIxVgIi2bTXd_dGfaGvz"
                        onChange={onChangeRecapthca}
                        ref={recaptchaRef}
                    />

                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <div>
                    <Button type="submit" color={"blue"} className="w-full" disabled={!captchatoken}>Sign In</Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default Login;