import { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import NavHeader from "../../components/NavHeader";
import { Button, Label, TextInput, Select } from "flowbite-react";
import axiosInstance from '../../axiosInstance';
import { useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';

export default function EditClient() {
    const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const [id, setID] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [updated_at, setUpdatedAt] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    const navigate = useNavigate();
    const { id2 } = useParams();

    useEffect(() => {
        getClientById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getClientById = async () => {
        const response = await axiosInstance.get(`/client/${id2}`);
        setUsername(response.data.username);
        setEmail(response.data.email);
        setRole(response.data.role);
    };

    const updateClient = {
        id: id2,
        username: username,
        email: email,
        role: role,
        updated_at: updated_at
    }


    const handleUpdateClient = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`/client/update/${id2}`, updateClient).then(() => {
                navigate("/client/list");
            });
            console.log("Post created:", response.data);
        } catch (error) {
            console.error("Error creating post:", error);
        }
    };

    return (
        <>
            <Aside />
            <NavHeader />
            <div id="main-content" className="relative h-screen w-auto mt-[73px] p-4 overflow-auto bg-gray-100 lg:ml-64 dark:bg-gray-900">
                <h5 className="text-center mb-4 text-xl">Edit Client</h5>
                <form className="flex w-auto flex-col gap-4" onSubmit={handleUpdateClient}>
                    <input type="hidden" name="id" value={id2} onChange={() => setID(id2)} />
                    <input type="hidden" name="updated_at" value={updated_at} onChange={() => setUpdatedAt(now)} />
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="title" value="Username" />
                        </div>
                        <TextInput
                            value={username}
                            name="username"
                            onChange={(e) => setUsername(e.target.value)}
                            type="text"
                            placeholder="Leave a text for title"
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="text" value="E-mail" />
                        </div>
                        <TextInput
                            id="text"
                            value={email}
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Leave a text for content..."
                            required shadow />
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="text" value="role" />
                        </div>
                        <Select id="role" name="role" value={role} onChange={(e) => setRole(e.target.value)} required>
                            <option>Choose Role</option>
                            <option value={"Admin"}>Admin</option>
                            <option value={"Users"}>Users</option>
                            <option value={"Support"}>Support</option>
                        </Select>
                    </div>
                    <Button color="warning" type="submit">Update</Button>
                </form>
            </div>
        </>
    );
}